const settings = reactive({

    loaded: false,
    content: {
        header: null,
        footer: null
    },
    editing: false,
    app: { debug: false },
    categories: [],
    languages: {},
    page_loading:false,
    route_blacklist: ['autodiscover', "autodiscover.xml","AJVaccines_2020_370x110px_HiDPI.png"],
    content_version: "draft" as "draft" | "published" | undefined,
    image_compression_default: "/m/filters:quality(80)",
    image_compression_portrait:"/m/filters:quality(80)"
})

function handleMenuItem(menuitem: any, links: any, locale: string) {
    try {
        //   console.log(menuitem || menuitem )
        if (menuitem.link?.id != '') {
            // console.log(menuitem.link.story)
            // menuitem.link.real_story = links.find((l: any) => {
            //     return menuitem.link.story?.uuid == l?.uuid
            // })
            // console.log(menuitem.link?.story.translated_slugs)
            menuitem.link.story.full_slug = menuitem.link.story.full_slug
                .replace('pages', '')
                .replace(/\/$/, '')
            if (locale != 'en') {
                menuitem.link.story.full_slug = menuitem.link.story.full_slug.replace(
                    locale + '/',
                    '',
                )
            }
        } else {
            // console.log("faulty", menuitem)
        }

        if (menuitem.submenu.length > 0) {
            menuitem.submenu.forEach((sub: any) => {
                handleMenuItem(sub, links, locale)
            })
        }
        // console.log(menuitem)
    } catch (error) {
        console.error(error)
    }
}


// console.log("before",cache.value)
// const cache = ref({ loaded: false, footer: null, header_menu: null, home: null, error_page: null, active_story: null, links: null })


const requests = ref(0)
const isLoading = ref(false)
export default async (_caller:any) => {
    const runtimeConfig = useRuntimeConfig()
    const { handleSlug } = usePageManager();
    const data = ref({})
    const { cached_data, updateCache } = useCache()
    const route = useRoute();
    const { slug } = route.params;

    let _slug = handleSlug(slug);
    if (_slug.includes("pages/")) _slug = _slug.replace("pages/", "");
    // console.log("useSettings ('", _caller,"')", `as ${process.server ? 'server' : 'client' }`)
    // const s_data = useState('s-data', () => {
    //     console.log("here!!!", process.client)
    //     return {lorem:'as'};

    // })
    // if(process.server) console.log("useSettings - _caller : ",_caller)
    const storyblokApi = useStoryblokApi()
    const { locale, locales } = useI18n()
    settings.editing = process.client ? (window && window.location.href.indexOf('_storyblok') > -1) || false : false
    settings.content_version = runtimeConfig.public.content_version as "draft" | "published" | undefined
    const getStory = async (_slug: string) => {
        if (process.server) console.log(_slug, "content_version :", settings.content_version, " - loading...")
        //         console.log(cached_data.value.active_story?.slug,_slug)
        // console.log(cached_data.value.active_story?.lang, locale.value)
        if (cached_data.value.active_story?.slug == _slug && (cached_data.value.active_story?.lang == "default" && locale.value == "en")) {
            // console.log("story already cached")
            return;
        }
       
        cached_data.value.page_loading = true
        try {
        let s = await useAsyncStoryblok(`pages/${_slug}`, {
            version: settings.content_version as "draft" | "published" | undefined,
            // resolve_relations: "link.story",
            resolve_links: "url",
            language: locale.value,
        });
       
        updateCache({
            page_loading: false,
            active_story: s.value || cached_data.value.error_page,
            header_color: s.value?.content?.settings_header_color?.enabled ? s.value?.content?.settings_header_color?.color : '#000'
        })
            
    
       
        }
        catch (e: any) { 
      
            updateCache({
                page_loading: false,
                active_story: cached_data.value.error_page,
                header_color:'#000'
            })

        }

      
        
        
      
       
        // catch (e: any) {
        //     // return false;
        //     // console.log(e);
           
        //         throw createError({
        //             statusCode: e.status,
        //             statusMessage: e.response
        //         });
        //     return false;
        // }
        // if (s.value.status) {
        //     throw createError({
        //         statusCode: s.value.status,
        //         statusMessage: s.value.response
        //     });
        // }
     
        //  cached_data.value.page_loading = false
        // //  console.log(s.value)
        // cached_data.value.active_story = s.value || cached_data.value.error_page;
        // return s.data.story
        // if(process.server)console.log(_slug,"done...")
    }
    const fetchInitialData = async () => {

       const {refresh} = await useAsyncData('main-data',async () => {
            requests.value++
            console.log("init with slug",slug, locale.value)
            if(process.server)console.log("loading data content_version :",settings.content_version)

            const main = await storyblokApi.get('cdn/stories/main', {
                version: settings.content_version as "draft" | "published" | undefined,
                resolve_links: '1',
                language: locale.value == 'en' ? '' : locale.value,
                resolve_relations: 'PageLink.story',
            })
            main.data.story.content.header_menu.forEach((mitem: any) => {
                handleMenuItem(mitem, main.data.links, locale.value)
            })
            //   console.log(main.data.story.content.header_menu)
            const home = await storyblokApi.get('cdn/stories/pages/home', {
                version: settings.content_version,
                resolve_links: '1',
                excluding_fields:'content',
                language: locale.value == 'en' ? '' : locale.value,
                resolve_relations: 'PageLink.story',
            })
            const error_page = await storyblokApi.get('cdn/stories/pages/404', {
                version: settings.content_version,
                resolve_links: '1',
                language: locale.value == 'en' ? '' : locale.value,
                resolve_relations: 'PageLink.story',
            })
            await getStory(_slug)
            // const translated_links = await storyblokApi.get('cdn/stories/pages')
            // console.log(main.data)
            // console.log('CV – ', 'main:', main.data.cv, 'home:', home.data.cv)
           //    console.log(main.data.links)
           let updata = {

               footer: main.data.story.content.footer,
               home: home.data.story,
               error_page: error_page.data.story,
               header_menu: main.data.story.content.header_menu,
               links: main.data.links,
               meta: { title: main.data.story.content.meta_title, description: main.data.story.content.meta_description, image: main.data.story.content.meta_image },
               loaded: true,
               page_loading: true,
               loading: false
           }
           updateCache(updata);
          
            // cached_data.value.loading = false;
            // console.log(main.data.links)
             if(process.server)console.log("## cache data loaded ##",`is client : ${process.client}`)
             if(process.server)console.log("–––––––––––––––––––––––––––––––")
            // console.log(cached_data)
           //    console.log(cache.value)
           return updata;
            // return cache.value
        }, {
            //  watch: [locale]
       })
    //    return refresh
        
    }
    // console.log(slug.includessettings.route_blacklist)
    let halt:any = false
    settings.route_blacklist.forEach((rb) => {
        if (slug.includes(rb)) {
            halt = rb;
       }
    })
    if (halt) {
        console.log("!!! Halting datafetching because of bad route :",halt, '\nroute :',slug, '\nblacklist : ',settings.route_blacklist)
    }
    // console.log(halt)
        
        // console.log()
        if (process.server && !cached_data.value.loading && !cached_data.value.loaded && !halt) {
            // console.log(cached_data.value)
            cached_data.value.loading = true
            
            // watch(locale, (newVal) => {
            //     "locale change"
            // },{deep:true})
             if(process.server) console.log("fetchInitialData", `is client : ${process.client}`,)
            // console.log("here––––")
            let refr = await fetchInitialData();
           
            // usaeState('refresh-data', () => {return refr})
          
         } else{
            // console.log("dont fetch new data")
         }


   
    const updateData = async () => {
        cached_data.value.loading = true
        await fetchInitialData()
        return true
    }
    const setActiveStory = (story:any) => {
        cached_data.value.active_story = story;
    }
    const udpateBreadcrumbs = () => {
        
    }
    
    // if(process.server) await loadSettings()
    // console.log('–––––––––––––––––––––––––––––––––--',data)
    return { ...toRefs(settings), isLoading, data, cached_data,halt,setActiveStory, fetchInitialData, updateData, getStory }

}